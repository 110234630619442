require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

window.jQuery = $;
window.$ = $;

import toastr from 'toastr';

import 'magnific-popup/dist/jquery.magnific-popup.js'
import 'magnific-popup/dist/magnific-popup.css'

import {tns} from "../../../node_modules/tiny-slider/src/tiny-slider";
import Plyr from 'plyr';
import previewImage from '../../assets/images/player-preview.png';
import {CountUp} from 'countup.js';
import Masonry from 'masonry-layout';
import '../src/header.scss'
import '../src/home.scss'


import 'plyr/dist/plyr.css'
import '../src/custom.scss' // Support component names relative to this directory:

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

const lang = document.getElementsByClassName('header__language');

if (lang[0]) {
  localStorage.setItem('lang', lang[0].text == "RU" ? 'en' : 'ru');
}

if (lang[0].text === "EN") {
  $.extend(true, $.magnificPopup.defaults, {
    tClose: 'Закрыть (Esc)',
    tLoading: 'Загрузка...',
    gallery: {
      tPrev: 'Предыдущий (Стрелка влево)',
      tNext: 'Следующий (Стрелка вправо)',
      tCounter: '%curr% из %total%'
    },
    image: {
      tError: '<a href="%url%">Изображение</a> не может быть загружено.'
    },
    ajax: {
      tError: '<a href="%url%">Данные</a> не могут быть загружены.'
    }
  });
} else {
  $.extend(true, $.magnificPopup.defaults, {
    tClose: 'Close (Esc)',
    tLoading: 'Loading...',
    gallery: {
      tPrev: 'Previous (Left arrow key)',
      tNext: 'Next (Right arrow key)',
      tCounter: '%curr% of %total%'
    },
    image: {
      tError: '<a href="%url%">The image</a> could not be loaded.'
    },
    ajax: {
      tError: '<a href="%url%">The content</a> could not be loaded.'
    }
  });
}


if (document.querySelector('.content__slider--talents')) {
  const talentsSlider = tns({
    container: '.content__slider--talents',
    items: 6,
    gutter: 16,
    slideBy: 1,
    loop: false,
    autoplay: true,
    controls: false,
    nav: false,
    autoplayButtonOutput: false,
    responsive: {
      320: { gutter: 12, items: 2 },
      480: { gutter: 12, items: 3 },
      600: { gutter: 12, items: 4 },
      800: { gutter: 16, items: 5 },
      1000: { gutter: 16, items: 6 }
    }
  });

  document.querySelector('.content__talents-action-left').addEventListener('click', () => {
    talentsSlider.goTo('prev');
  });

  document.querySelector('.content__talents-action-right').addEventListener('click', () => {
    talentsSlider.goTo('next');
  });
}

if (document.querySelector('.content__slider--talents')) {
  const staffSlider = tns({
    container: '.content__slider--staff',
    items: 6,
    gutter: 16,
    slideBy: 1,
    loop: false,
    autoplay: true,
    controls: false,
    nav: false,
    autoplayButtonOutput: false,
    responsive: {
      320: { gutter: 12, items: 2 },
      480: { gutter: 12, items: 3 },
      600: { gutter: 12, items: 4 },
      800: { gutter: 16, items: 5 },
      1000: { gutter: 16, items: 6 }
    }
  });

  document.querySelector('.content__staff-action-left').addEventListener('click', () => {
    staffSlider.goTo('prev');
  });

  document.querySelector('.content__staff-action-right').addEventListener('click', () => {
    staffSlider.goTo('next');
  });
}

if (document.querySelector('.content__slider--companies')) {
  const companiesSlider = tns({
    container: '.content__slider--companies',
    items: 6,
    autoWidth: true,
    gutter: 50,
    slideBy: 1,
    loop: false,
    autoplay: true,
    controls: false,
    nav: false,
    autoplayButtonOutput: false,
    responsive: {
      320: { gutter: 15, items: 3 },
      480: { gutter: 15, items: 4 },
      600: { gutter: 15, items: 6 },
      800: { gutter: 20, items: 6 },
      1000: { gutter: 20, items: 8 }
    }
  });

  document.querySelector('.content__companies-action-left').addEventListener('click', () => {
    companiesSlider.goTo('prev');
  });

  document.querySelector('.content__companies-action-right').addEventListener('click', () => {
    companiesSlider.goTo('next');
  });
}

if (document.querySelector('.content__slider--partners')) {
  const partnersSlider = tns({
    container: '.content__slider--partners',
    "autoWidth": true,
    items: 6,
    gutter: 16,
    slideBy: 1,
    loop: false,
    autoplay: true,
    controls: false,
    nav: false,
    autoplayButtonOutput: false,
    responsive: {
      320: { gutter: 12, items: 2 },
      480: { gutter: 12, items: 3 },
      600: { gutter: 12, items: 4 },
      800: { gutter: 16, items: 5 },
      1000: { gutter: 16, items: 6 }
    }
  });

  document.querySelector('.content__partners-action-left').addEventListener('click', () => {
    partnersSlider.goTo('prev');
  });

  document.querySelector('.content__partners-action-right').addEventListener('click', () => {
    partnersSlider.goTo('next');
  });
}

if (document.querySelector('.content__slider--films')) {
  const filmsSlider = tns({
    container: '.content__slider--films',
    items: 4,
    gutter: 25,
    slideBy: 1,
    loop: false,
    autoplay: true,
    controls: false,
    nav: false,
    autoplayButtonOutput: false,
    responsive: {
      320: { gutter: 15, items: 2 },
      480: { gutter: 15, items: 3 },
      600: { gutter: 15, items: 3 },
      800: { gutter: 20, items: 4 },
      1000: { gutter: 20, items: 4 }
    }
  });

  document.querySelector('.content__films-action-left').addEventListener('click', () => {
    filmsSlider.goTo('prev');
  });

  document.querySelector('.content__films-action-right').addEventListener('click', () => {
    filmsSlider.goTo('next');
  });
}

if (document.querySelector('.content__slider--talent-review')) {
  const talentReviewSlider = tns({
    container: '.content__slider--talent-review',
    items: 1,
    gutter: 25,
    slideBy: 1,
    loop: true,
    autoplay: false,
    autoplayTimeout: 10000,
    controls: false,
    nav: false,
    autoplayButtonOutput: false,
    responsive: {
      320: { gutter: 15, items: 1 },
      480: { gutter: 15, items: 2 },
      600: { gutter: 15, items: 3 },
      800: { gutter: 20, items: 4 },
      1000: { gutter: 20, items: 4 }
    }
  });

  document.querySelector('.content__talent-review-action-left').addEventListener('click', () => {
    talentReviewSlider.goTo('prev');
  });

  document.querySelector('.content__talent-review-action-right').addEventListener('click', () => {
    talentReviewSlider.goTo('next');
  });
}

if (document.querySelector('.content__slider--talent-casting')) {
  const talentCastingSlider = tns({
    container: '.content__slider--talent-casting',
    items: 1,
    gutter: 25,
    slideBy: 1,
    loop: false,
    autoplay: true,
    controls: false,
    nav: false,
    autoplayButtonOutput: false,
    responsive: {
      320: { gutter: 15, items: 1 },
      480: { gutter: 15, items: 1 },
      600: { gutter: 15, items: 3 },
      800: { gutter: 20, items: 4 },
      1000: { gutter: 20, items: 4 }
    }
  });

  document.querySelector('.content__talent-casting-action-left').addEventListener('click', () => {
    talentCastingSlider.goTo('prev');
  });

  document.querySelector('.content__talent-casting-action-right').addEventListener('click', () => {
    talentCastingSlider.goTo('next');
  });
}


if (document.querySelector('.content__slider--location')) {
  const locationSlider = tns({
    container: '.content__slider--location',
    items: 4,
    gutter: 25,
    slideBy: 1,
    autoplay: true,
    controls: false,
    nav: false,
    autoplayButtonOutput: false,
    responsive: {
      320: { gutter: 15, items: 2 },
      480: { gutter: 15, items: 2 },
      600: { gutter: 15, items: 3 },
      800: { gutter: 20, items: 4 },
      1000: { gutter: 20, items: 4 }
    }
  });

  document.querySelector('.content__location-action-left').addEventListener('click', () => {
    locationSlider.goTo('prev');
  });

  document.querySelector('.content__location-action-right').addEventListener('click', () => {
    locationSlider.goTo('next');
  });
}

if (document.querySelector('.casting__main-slider--preview')) {
  const mainSlider = tns({
    container: '.casting__main-slider--preview',
    items: 1,
    slideBy: 1,
    "mouseDrag": true,
    gutter: 10,
    "edgePadding": 75,
    loop: false,
    autoplay: true,
    "autoplayTimeout": 5000,
    "autoplayHoverPause": true,
    rewind: true,
    controls: false,
    nav: false,
    autoplayButtonOutput: false,
    responsive: {
      320: { items: 1, "edgePadding": 0 },
      480: { items: 1, "edgePadding": 0 },
      600: { items: 2, "edgePadding": 0 },
      800: { items: 3, "edgePadding": 75, },
      1000: { items: 3, "edgePadding": 75, }
    }
  });

  document.querySelector('.content__talent-main-casting-action-left').addEventListener('click', () => {
    mainSlider.goTo('prev');
  });

  document.querySelector('.content__talent-main-casting-action-right').addEventListener('click', () => {
    mainSlider.goTo('next');
  });
}

if (document.querySelector('.header__burger')) {
  document.querySelector('.header__burger').addEventListener('click', () => {
    document.querySelector('.header__burger-overlay').classList.add('header__burger-overlay--is-open');
  });

  document.querySelector('.header__burger-close').addEventListener('click', () => {
    document.querySelector('.header__burger-overlay').classList.remove('header__burger-overlay--is-open');
  });
}

const logoutButton = document.querySelector('.logout-button-js');

if (logoutButton) {
  logoutButton.addEventListener('click', () => {
    localStorage.removeItem('eta_token');
  });
}

document.querySelectorAll('.faq__more').forEach(moreButton => {
  moreButton.addEventListener('click', () => {
    const preview = moreButton.previousSibling;
    const content = moreButton.nextSibling;

    preview.classList.add('faq__preview--is-hide');
    content.classList.add('faq__content--is-show');
    moreButton.parentNode.previousSibling.firstElementChild.textContent = '–';
    moreButton.classList.add('faq__more--is-hide');
  });
})

const playerTalentWelcome = new Plyr('#player-talent-welcome', { host: 'https://www.youtube.com', widget_referrer: "https://www.etalents.pro", origin: "https://www.etalents.pro" });

if (document.querySelector('.for-talent__video-link')) {
  document.querySelector('.for-talent__video-link').addEventListener('click', () => {
    document.querySelector('.modal__overlay--talent-video').classList.add('modal__overlay--is-open');
  });

  document.querySelector('.modal__close').addEventListener('click', () => {
    playerTalentWelcome.stop();
    document.querySelector('.modal__overlay--talent-video').classList.remove('modal__overlay--is-open');
  });
}


// Home page. Castings modals logic open and close______________________________________________________________________
const homeCastingSliderPreviews = document.querySelectorAll('.casting__slider--preview__img');

homeCastingSliderPreviews.forEach(review => {
  review.addEventListener('click', () => {
    const id = review.dataset.id
    document.querySelector(`#modal__overlay__advertisement-${id}`).classList.add('modal__overlay--is-open');
  })

  const allXButtons = document.querySelectorAll('.modal__overlay--review .modal__close');

  allXButtons.forEach(button => {
    button.addEventListener('click', (e) => {
      const id = e.target.dataset.id
      document.querySelector(`#modal__overlay__advertisement-${id}`).classList.remove('modal__overlay--is-open');
    });
  })
})

const talentReviews = document.querySelectorAll('.talent-review__image-overlay--review');

talentReviews.forEach(review => {
  review.addEventListener('click', () => {
    document.querySelector('.modal__overlay--review').classList.add('modal__overlay--is-open');

    // Get main text of review
    let fullQuote = review.querySelector('.talent-review__quote-full').textContent;
    let fullPString = ``
    let firstDiv = document.createElement("div");
    // If text include [brake], replace it with tag <br> to move the paragraph to a new line
    // Because we take text from locale for_staff.yml, for_talent.yml
    fullQuote.split('[brake]').map((elem) => {
      fullPString += `${elem}<br/><br/>`;
    })
    firstDiv.innerHTML = fullPString;
    const firstBlock = firstDiv.innerHTML;

    const nameText = review.nextElementSibling.textContent;
    const newDiv = document.createElement("div");
    newDiv.innerHTML = `<p>${nameText}</p>`;
    const nameTag = newDiv.innerHTML;

    document.querySelector('.talent-review__modal-text').innerHTML = firstBlock + nameTag;
  });

  document.querySelector('.modal__overlay--review .modal__close').addEventListener('click', () => {
    document.querySelector('.modal__overlay--review').classList.remove('modal__overlay--is-open');
  });
})

if ($('#popup-photo').length) {
  $('#popup-photo').magnificPopup({
    delegate: 'img',
    type: 'image',
    gallery:{enabled:true},
    callbacks: {
      elementParse: function(pic) {
        pic.src = pic.el.attr('src');
      }
    }
  });
}


$(window).on('load', function () {
  const talentCount = document.querySelector('.about-us__talent-count');
  const staffCount = document.querySelector('.about-us__staff-count');
  const companyCount = document.querySelector('.about-us__company-count');

  const talentCountUp = talentCount && new CountUp(talentCount, Number(talentCount.textContent));
  const staffCountUp = talentCount && new CountUp(staffCount, Number(staffCount.textContent));
  const companyCountUp = talentCount && new CountUp(companyCount, Number(companyCount.textContent));

  talentCountUp && talentCountUp.start();
  staffCountUp && staffCountUp.start();
  companyCountUp && companyCountUp.start();

  const companyPhotos = document.querySelector('.company-page__photos');

  if (companyPhotos) {
    new Masonry(companyPhotos, {
      itemSelector: '.company-page__photo',
      percentPosition: true
    });
  }

  const filmPhotos = document.querySelector('.film-page__photos');

  if (filmPhotos) {
    new Masonry(filmPhotos, {
      itemSelector: '.film-page__photo',
      percentPosition: true
    });
  }

  if (document.querySelector('.company-contacts-js')) {
    document.querySelector('.company-contacts-js').addEventListener('click', () => {
      document.querySelector('.modal__overlay--company-contacts').classList.add('modal__overlay--is-open');
    });

    document.querySelector('.modal__close--company-contacts').addEventListener('click', () => {
      document.querySelector('.modal__overlay--company-contacts').classList.remove('modal__overlay--is-open');
    });
  }

  if (document.querySelector('.company-download-cv-js')) {
    document.querySelector('.company-download-cv-js').addEventListener('click', () => {
      document.querySelector('.modal__overlay--company-download-cv').classList.add('modal__overlay--is-open');
    });

    document.querySelector('.modal__close--company-download-cv').addEventListener('click', () => {
      document.querySelector('.modal__overlay--company-download-cv').classList.remove('modal__overlay--is-open');
    });

    document.querySelector('.modal__close--company-download-cv').addEventListener('click', () => {
      document.querySelector('.modal__overlay--company-download-cv').classList.remove('modal__overlay--is-open');
    });
  }

  if (document.querySelector('.company-cv-js')) {
    document.querySelectorAll('.company-cv-js').forEach((element) => {
      element.addEventListener('click', () => {
        document.querySelector('.modal__overlay--company-cv').classList.add('modal__overlay--is-open');
      });
    })

    document.querySelector('.modal__close--company-cv').addEventListener('click', () => {
      document.querySelector('.modal__overlay--company-cv').classList.remove('modal__overlay--is-open');
    });

    document.querySelector('.modal__close--company-cvv').addEventListener('click', () => {
      document.querySelector('.modal__overlay--company-cv').classList.remove('modal__overlay--is-open');
    });
  }

  if (document.querySelector('.company-address-js')) {
    document.querySelector('.company-address-js').addEventListener('click', () => {
      document.querySelector('.modal__overlay--company-address').classList.add('modal__overlay--is-open');
    });

    document.querySelector('.modal__close--company-address').addEventListener('click', () => {
      document.querySelector('.modal__overlay--company-address').classList.remove('modal__overlay--is-open');
    });
  }

  if (document.querySelector('.company-page__data-table-header_flex__hide-button')) {
    const button = document.querySelector('.company-page__data-table-header_flex__hide-button');

    button.addEventListener('click', () => {
      const appearanceBlocks = document.querySelectorAll('.company-page__appearance-block');

      appearanceBlocks.forEach( appearanceBlock => {
        if (appearanceBlock.classList.contains('hide')) {
          appearanceBlock.classList.remove('hide');
          appearanceBlock.classList.add('show');

          const lang = document.URL.split('/')[3] === "en" ? 'HIDE' : 'СКРЫТЬ';
          button.querySelector('span').innerText = lang;
          button.querySelector('.red-cube').style.marginTop = `4px`;
          button.querySelector('.red-cube').style.transform = `rotate(45deg)`

        } else {
          appearanceBlock.classList.remove('show');
          appearanceBlock.classList.add('hide');

          const lang = document.URL.split('/')[3] === "en" ? 'SHOW' : 'ПОКАЗАТЬ';
          button.querySelector('span').innerText = lang;
          button.querySelector('.red-cube').style.marginTop = `-8px`;
          button.querySelector('.red-cube').style.transform = `rotate(-135deg)`
        }
      });
    });
  }

  if (document.querySelector('.company-page__data-table-header_flex__hide-button-education')) {
    const button = document.querySelector('.company-page__data-table-header_flex__hide-button-education');
    const appearanceBlocks = [...document.querySelectorAll('.company-page__education-block')].filter(elem => elem.dataset.index > 0);

    appearanceBlocks.forEach(elem => {
      elem.classList.add('hide');
    });

    button.addEventListener('click', () => {
      appearanceBlocks.forEach( appearanceBlock => {
        if (appearanceBlock.classList.contains('hide')) {
          appearanceBlock.classList.remove('hide');
          appearanceBlock.classList.add('show');

          button.querySelector('span').innerText = document.URL.split('/')[3] === "en" ? 'HIDE' : 'СКРЫТЬ';
          button.querySelector('.red-cube').style.marginTop = `4px`;
          button.querySelector('.red-cube').style.transform = `rotate(45deg)`

        } else {
          appearanceBlock.classList.remove('show');
          appearanceBlock.classList.add('hide');

          button.querySelector('span').innerText = document.URL.split('/')[3] === "en" ? 'SHOW' : 'ПОКАЗАТЬ';
          button.querySelector('.red-cube').style.marginTop = `-8px`;
          button.querySelector('.red-cube').style.transform = `rotate(-135deg)`
        }
      });
    });
  }

  if (document.querySelector('.company-page__data-table-header_flex__hide-button__portfolio')) {
    const button = document.querySelector('.company-page__data-table-header_flex__hide-button__portfolio');
    const roleBlocks = [...document.querySelectorAll('.company-page__appearance-info-portfolio')].filter(elem => elem.dataset.index > 2);

    roleBlocks.forEach(elem => {
      elem.classList.add('hide');
    });

    button.addEventListener('click', () => {
      roleBlocks.forEach( roleBlock => {
        if (roleBlock.classList.contains('hide')) {
          roleBlock.classList.remove('hide');
          roleBlock.classList.add('show');

          button.querySelector('span').innerText = document.URL.split('/')[3] === "en" ? 'HIDE' : 'СКРЫТЬ';
          button.querySelector('.red-cube').style.marginTop = `4px`;
          button.querySelector('.red-cube').style.transform = `rotate(45deg)`

        } else {
          roleBlock.classList.remove('show');
          roleBlock.classList.add('hide');

          button.querySelector('span').innerText = document.URL.split('/')[3] === "en" ? 'SHOW' : 'ПОКАЗАТЬ';
          button.querySelector('.red-cube').style.marginTop = `-8px`;
          button.querySelector('.red-cube').style.transform = `rotate(-135deg)`
        }
      });
    });
  }

  if (document.querySelector('.company-page__data-table-header_flex__hide-button__clients')) {
    const button = document.querySelector('.company-page__data-table-header_flex__hide-button__clients');
    const roleBlocks = [...document.querySelectorAll('.company-page__profile-appearance-info__clients')].filter(elem => elem.dataset.index > 2);

    roleBlocks.forEach(elem => {
      elem.classList.add('hide');
    });

    button.addEventListener('click', () => {
      roleBlocks.forEach( roleBlock => {
        if (roleBlock.classList.contains('hide')) {
          roleBlock.classList.remove('hide');
          roleBlock.classList.add('show');

          button.querySelector('span').innerText = document.URL.split('/')[3] === "en" ? 'HIDE' : 'СКРЫТЬ';
          button.querySelector('.red-cube').style.marginTop = `4px`;
          button.querySelector('.red-cube').style.transform = `rotate(45deg)`

        } else {
          roleBlock.classList.remove('show');
          roleBlock.classList.add('hide');

          button.querySelector('span').innerText = document.URL.split('/')[3] === "en" ? 'SHOW' : 'ПОКАЗАТЬ';
          button.querySelector('.red-cube').style.marginTop = `-8px`;
          button.querySelector('.red-cube').style.transform = `rotate(-135deg)`
        }
      });
    });
  }
});

// Copy links to clipboard
if ($('.main-share-link').length) {
  const copyLink = $('.main-share-link');

  copyLink.on('click', function(){
    const link = $('#copy_link');
    link.show();
    link.select();
    document.execCommand("copy");
    link.hide();
    copyLink.css('color', 'red');
    setTimeout(() => {
      copyLink.css('color', 'white');
    }, 250)
  });
}

// Calculation iframe sizes
window.onload = function() {
  if ($('.staff-main-video').length) {
    const block = document.querySelector('.staff-main-video');
    const width = block.offsetWidth;

    const iFrames = document.getElementsByClassName('i-frame');
    for (let elem of iFrames) {
      elem.children[0].style.height = Math.floor(width / 1.77) + 'px'
    }
  }

  document.querySelectorAll('.plyr-player').forEach((player) => {
    new Plyr(player);
  });

  const mainPlayer = document.querySelector('.main-plyr-player');
  const mainPlyrPlayer = new Plyr(mainPlayer);
  mainPlyrPlayer.poster = previewImage;

  document.querySelectorAll('.main-share-link').forEach((element) => {
    element.addEventListener('click', () => {
      const input = element.querySelector('.copy_link');
      input.style = 'display: block;';
      input.select();
      input.setSelectionRange(0, 99999);
      document.execCommand('copy');
      input.style = 'display: none;';
    });
  });
};

if ($('.copy-link-flash').length) {
  toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
  }

  const copyLink = $('.copy-link-flash');
  let page_lang = localStorage.getItem("lang");

  copyLink.on('click', function(){
    if (page_lang === 'ru') {
      toastr["success"]("", "Ссылка скопирована")
    } else {
      toastr["success"]("", "Link copied")
    }
  });
}

$('.location__submit').on('click', (e) => {

  toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-top-right",
    "preventDuplicates": true,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
  }

  $('.form__location')
    .on('ajax:success', function() {
      document.querySelectorAll('.form__location input').forEach((input) => {
        input.value = ''
      })
      document.querySelector('.form__location textarea').value = ''
      toastr["success"]("", "Заявка успешно отправлена")
    })
    .on('ajax:error', function () {
      toastr["error"]("", "Заполните все поля!")
    })
})

$('.scroll_to_top').on('click', (e) => {
  $("html, body").animate({ scrollTop: 0 }, "slow");
  return false;
})
